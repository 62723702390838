import { CircularProgress, Dialog, DialogContent, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { nowAreaState } from '../atom/atom';
import LogoSrc from "../images/main-logo.png";

function LoadingPage(props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=7c62fd16c038e08bbc3326ad42efb169&libraries=services,clusterer,drawing,replace`;
    script.async = true;
    script.onload = () => {
      if (window.kakao && window.kakao.maps) {
        initializeMap();
      } else {
        console.error("Kakao Maps API 로드에 실패했습니다.");
      }
    };
    script.onerror = () => {
      console.error("Kakao Maps API 스크립트 로드 에러");
      navigate(`/국립강릉원주대학교 강릉캠퍼스/강릉시/지변동`);
    };
    document.head.appendChild(script);

    function initializeMap() {
      const { kakao } = window;

      const mapContainer = document.getElementById("map");
      const mapOption = {
        center: new kakao.maps.LatLng(37.566826, 126.9786567), // 지도의 초기 중심좌표
        level: 3 // 지도의 확대 레벨
      };

      // 지도를 생성
      const map = new kakao.maps.Map(mapContainer, mapOption);

      // 장소 검색 객체를 생성
      const geocoder = new kakao.maps.services.Geocoder();

      // 현재 위치 가져오기
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          const lat = position.coords.latitude; // 위도
          const lng = position.coords.longitude; // 경도

          // 현재 위치를 지도의 중심으로 설정
          const locPosition = new kakao.maps.LatLng(lat, lng);
          map.setCenter(locPosition);

          // 현재 위치에 마커 추가
          const marker = new kakao.maps.Marker({
            map: map,
            position: locPosition
          });

          // 현재 위치를 콘솔에 출력
          console.log("현재 위치:", lat, lng);

          // 위도, 경도를 주소로 변환
          searchAddrFromCoords(locPosition, displayCenterInfo);
        }, function (error) {
          console.error("Geolocation API를 사용할 수 없습니다.", error);
          navigate(`/국립강릉원주대학교 강릉캠퍼스/강릉시/지변동`);
        });
      } else {
        console.error("Geolocation API를 지원하지 않는 브라우저입니다.");
        navigate(`/국립강릉원주대학교 강릉캠퍼스/강릉시/지변동`);
      }

      function searchAddrFromCoords(coords, callback) {
        // 좌표로 주소 정보를 요청합니다
        geocoder.coord2Address(coords.getLng(), coords.getLat(), (result, status) => {
          console.log("위도:", coords.getLat());
          console.log("경도:", coords.getLng());
          callback(result, status, coords); // coords를 함께 전달
        });
      }

      function displayCenterInfo(result, status, coords) {
        if (status === kakao.maps.services.Status.OK) {
          const detailAddr = result[0].road_address?.building_name || result[0].address.address_name;

          const newAddress = {
            si: result[0].address.region_1depth_name,
            dong: result[0].address.region_3depth_name,
            fullAddress: detailAddr,
            latitude: coords.getLat(), // 위도 추가
            longitude: coords.getLng() // 경도 추가
          };

          setNowAddress(newAddress);

          console.log('주소:', result[0]);
          console.log('현재 주소:', newAddress); // 위도, 경도 포함된 주소 출력
          navigate(`/${newAddress.fullAddress}/${newAddress.si}/${newAddress.dong}`);
        } else {
          navigate(`/국립강릉원주대학교 강릉캠퍼스/강릉시/지변동`);
        }
      }
    }
  }, []);

  return (
    <>
      <div id="map" className="map"></div>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: theme.palette.background.paper
          }
        }}
        open={true}
      >
        <DialogContent
          sx={{
            padding: "32px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <img
            width={"200px"}
            src={LogoSrc}
            alt="Logo"
          />
          <CircularProgress
            size="60px"
            sx={{
              color: "white",
              position: "absolute",
              top: "100px"
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default LoadingPage;
