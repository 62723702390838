import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MainWriteResBar from '../Bar/MainWriteResBar';
import { List, ListItem, useMediaQuery } from '@mui/material';
import { communityValues, deliveryValues, taxiValues } from '../../const/const';
import { useRecoilState } from 'recoil';
import { nowAreaState } from '../../atom/atom';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import HorizontalDivider from '../HorizontalDivider';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function MainWriteCard({ title, writes, onClick, width = null, placeType }) {
  const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  
  const goViewWritePage = async (writeType, placeType, row) => {
    sessionStorage.setItem("viewWrite", JSON.stringify(row));
    navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${writeType}-table/${placeType}-place/view-write/1`);
  };

  return (
    <Card 
      variant='outlined'
      sx={{
        minWidth: "300px",
        width: width
      }}
    >
      <CardContent>
        <MainWriteResBar
          title={title}
          onClick={onClick}
        />
        <HorizontalDivider width={"100%"} />
        <List sx={{
          height: "calc(53px * 5)",
          width: '100%', 
          bgcolor: 'background.paper' 
        }}>
          {writes && writes.map((item) => (
            <ListItem key={item.id}>
              <Button
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  textAlign: "left",
                  width: "100%",
                  color: "black",
                  fontWeight: "600",
                  whiteSpace: "nowrap", // 텍스트를 한 줄로 유지
                  overflow: "hidden", // 넘치는 텍스트 숨기기
                  textOverflow: "ellipsis" // 넘치는 텍스트를 생략 부호(...)로 표시
                }}
                onClick={() => {
                  let writeType = null;
                  if (item.foodCategory && deliveryValues.includes(item.foodCategory)) {
                    writeType = "delivery";
                  }
                  if (item.foodCategory && taxiValues.includes(item.foodCategory)) {
                    writeType = "taxi";
                  }
                  if (item.foodCategory && communityValues.includes(item.foodCategory)) {
                    writeType = "community";
                  }
                  goViewWritePage(writeType, placeType, item);
                }}
              >
                <span>{"[" + item.registerDate + "]"}</span>
                <span>{"[" + item.foodCategory + "]"}</span>
                <span>{item.title === "" ? "-" : item.title}</span>
              </Button>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
